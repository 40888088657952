/* .loader {
  width: 100%;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: #090917;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader svg {
  width: 100%;
}

.loading-text {
  width: 100%;
  font-weight: bold;
  color: #fff;
  transform: translate(0.4rem);
  margin-top: 1rem;
} */

/* -------------------------------- */
/* ------- wahs | copyright ------- */
/* -------------------------------- */

/* -----Reset----- */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}*/

/* -----Common----- */
/*body {
  width: 100%;
  min-height: 100vh;
  background-color: #090917;
  overflow: hidden;
}*/

/* -----Container----- */
.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 100vh;
  background-color: #090917;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container > .icons {
  width: 300px;
}

.container > .icons path {
  fill: transparent;
  stroke: #854ce6;
  stroke-width: 15;
  /* stroke-opacity: 1; */
  /* cursor: pointer; */
}

/* ---Icons Loading--- */
.container .icons {
  margin-top: -20%;
  opacity: 0;
  animation: anim-load 1s linear forwards;
}

/* Animation */
@keyframes anim-load {
  0% {
    margin-top: -20%;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

/* ---SVG Paths--- */
/* Path 1 */
/* .container .icons path:first-child {
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
  animation: anim-icons1 2s linear forwards;
} */
.container .icons path {
  stroke-dasharray: 1700;
  stroke-dashoffset: 0;
  animation: anim-icons 5s linear forwards;
}

/* .container .icons:hover path:first-child {
  animation: anim-icons1 2s linear forwards;
} */

/* Animation */
@keyframes anim-icons {
  0% {
    stroke-dashoffset: 0;
  }

  40% {
    stroke-dashoffset: 500;
  }

  80% {
    stroke-dashoffset: 1000;
    fill: transparent;
  }
  90% {
    stroke-dashoffset: 1000;
    fill: #fff;
  }

  100% {
    stroke-dashoffset: 1000;
    fill: #854ce6;
  }
}

/* Path 2 */
/* .container .icons path:nth-child(2) {
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
  animation: anim-icons2 4s linear forwards 1s;
} */
/* .container .icons path {
  stroke-dasharray: 700;
  stroke-dashoffset: 0;
  animation: anim-icons2 4s linear forwards 1s;
} */

/* Animation */
/* @keyframes anim-icons2 {
  0% {
    stroke-dashoffset: 0;
  }

  40% {
    stroke-dashoffset: 500;
  }

  80% {
    stroke-dashoffset: 1000;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 1000;
    fill: #ffffff;
  }
} */
